import matrixModule from '@/store/matrix'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Matrix extends Vue {
  name = ''
  description = ''

  mounted() {
    const matrix = matrixModule.matrix
    if (matrix) {
      this.name = matrix.name
      this.description = matrix.description
    }
  }

  getData() {
    return { name: this.name, description: this.description }
  }

  async validate() {
    if (!this.$refs.observer_matrix) return
    const isValid = await (this.$refs.observer_matrix as any).validate()
    return isValid ? this.getData() : null
  }
}
